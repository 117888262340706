import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from 'react-bootstrap';
import logo from '../../src/images/ADSREEL-LOGO-WHITE.png';
import noProfileImage from '../../src/images/no-profile.png';
import { logoutUser } from "../Actions/AuthAction";
import { onNavbarClick } from "../Actions/AlertAction";

const Navbar = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand.data);
    const [isActive, setActive] = useState(false);
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    const handleToggle = () => {
        setActive(!isActive);
    };

    const handleClick = (e) => {
        let nav = e.target.name
        dispatch(onNavbarClick(nav));
    };

    const logout = () => {
        dispatch(logoutUser());
    }

    return (
        <>

            <header className="appHeader">
                <div className="container-fluid">
                    <div className="appHeader-main">
                        <div className="logo-wrap">
                            <div className="logo" style={{ padding: "20px" }}>
                                <Link to={'/dashboard'}><img alt="" className={`${rebrandData ? "logo-image-set" : ""} img-fluid`} src={rebrandData ? rebrandData.logo : logo} /></Link>
                            </div>
                        </div>
                        <div className="navExpand" onClick={handleToggle}><i className="fa fa-bars" aria-hidden="true"></i></div>
                        <div className={`appHeader-right ${isActive ? "act" : ""}`}>

                            {/* <div className="left-credit"><img src={require('../images/dollar.png')} /> 20/ left this week</div> */}

                            <div className="appHeader-nav">
                                <ul>
                                    <li><NavLink className={`nav-link`} to="/dashboard" onClick={(e) => handleClick(e)} >Dashboard</NavLink></li>
                                    {+auth.user.is_client_account === 1 ? "" :
                                        <li><NavLink className={`nav-link`} to="/create-video">Create Ads Video</NavLink></li>}
                                    <li><NavLink className={`nav-link`} to="/projects">Projects</NavLink></li>
                                    {+auth.user.is_client_account === 1 ? "" :
                                        <li className="nav-item"><NavLink className="nav-link" to="/integration" >Integration</NavLink></li>}
                                </ul>
                            </div>
                            <div className="appHeader-action">

                                <div className="action-nav">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic2">
                                            <div className="dropProfileImage"><img alt="" src={auth.user ? auth.user.image : noProfileImage} /></div>
                                            <div>
                                                <span className="welcometxt">Welcome</span>
                                                <span className="profileName">{auth.user?.name}</span>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-dark">

                                            <Link className="dropdown-item" to="/profile">
                                                <span className="mr-2"><i className="fas fa-user"></i></span> My Account
                                            </Link>
                                            {
                                                auth.user.is_client_account === "0" && auth.user.is_virtual_account === "0" ?
                                                    <>
                                                        <Link className="dropdown-item" to="/privacy">
                                                            <span className="mr-2"><i className="fas fa-key"></i></span> Privacy Setting
                                                        </Link>
                                                        {memberShip.includes("enterprise") || memberShip.includes("bundle") ?
                                                            <>
                                                                <Link className="dropdown-item" to="/account-management">
                                                                    <span className="mr-2"><i className="fas fa-users" ></i></span>
                                                                    Account Management
                                                                </Link>
                                                                <Link className="dropdown-item" to="/settings">
                                                                    <span className="mr-2"><i className="fa fa-gear"></i></span> Settings
                                                                </Link>
                                                            </> : ""}
                                                    </>
                                                    : ""
                                            }
                                            {
                                                memberShip.includes("unlimited") || memberShip.includes("bundle") ?
                                                    <Link className="dropdown-item" to="/reel-merge">
                                                        <span className="mr-2"><i className="fas fa-sign-out-alt"></i></span> ReelMerge
                                                    </Link> : ""
                                            }




                                            {auth.user.is_client_account === "0" ?
                                                memberShip.includes("enterprise") || memberShip.includes("unlimited") || memberShip.includes("bundle") ?
                                                    <Link className="dropdown-item" to="/upgrades">
                                                        <span className="mr-2"><i className="fas fa-plus-square"></i></span> Upgrades
                                                    </Link> : "" : ""}
                                            <Link className="dropdown-item" to="/help-and-support">
                                                <span className="mr-2"><i className="fas fa-headset"></i></span> Help & Support
                                            </Link>
                                            <span className="dropdown-divider"></span>
                                            <Link className="dropdown-item" onClick={() => logout()}>
                                                <span className="mr-2">
                                                    <i className="fas fa-sign-out-alt"></i>
                                                </span> Sign Out
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default React.memo(Navbar);