import React, { useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import AudioPlayer from './AudioPlayer';

const BgAudioLibrary = ({ currentUrl, setCurrentUrl, libraryAudio, pageCount, loader, error, fetchLibraryAudio, campaign, handleChangeBgAudio }) => {

    return (
        <>
            <div className="tab-outers" >
                <div className="audio-library" id='scrollableAudio123' style={{ maxHeight: "515px" }}>

                    <div className="tabSearch mt-3">
                        <input type="text"
                            readOnly
                            value={campaign.campaign.emotion.emotion}
                        />
                    </div>
                    <ul className='my-2'>
                        {campaign.backgroundResults ?
                            <AudioPlayer
                                url={campaign.backgroundResults.source}
                                title={"Result 1"}
                                currentUrl={currentUrl}
                                setCurrentUrl={setCurrentUrl}
                                campaign={campaign}
                                handleChangeAudio={handleChangeBgAudio}
                            />
                            : ""
                        }
                    </ul>

                    <InfiniteScroll
                        dataLength={libraryAudio.length} //This is important field to render the next data
                        next={() => fetchLibraryAudio(pageCount + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableAudio123"
                    >
                        <ul>
                            {libraryAudio.length > 0 ?
                                libraryAudio.map((curElem, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <AudioPlayer
                                                url={curElem.url}
                                                title={curElem.title}
                                                currentUrl={currentUrl}
                                                setCurrentUrl={setCurrentUrl}
                                                campaign={campaign}
                                                handleChangeAudio={handleChangeBgAudio}
                                            />
                                        </React.Fragment>
                                    )
                                })
                                : ""
                            }
                        </ul>
                    </InfiniteScroll>
                    {
                        loader.audioLibrary ?
                            <div className="col-md-12">
                                <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2 my-2" style={{ color: "#e0712c" }} /></h4>
                            </div>
                            : ''
                    }
                    {
                        error ?
                            <div className="col-md-12">
                                <h6 className="text-center">{error}</h6>
                            </div>
                            : ''
                    }


                </div>


            </div >

        </>
    )
}

export default BgAudioLibrary
