import React from 'react'
import sample from "../../../../images/sample.png";

const LogoComp = ({ logo, name, handleLogoWaterMark, onLoadFile, loadLogo }) => {
    return (
        <div className="add-block-single mt-0">
            <div className="add-block-main">
                <div className="add-block-title  mb-4">
                    <h2>Add Logo</h2>
                    <div className="switch-single">
                        <label className="switch" htmlFor="logo">
                            <input
                                type="checkbox"
                                checked={logo.enable}
                                id="logo"
                                onChange={(e) => handleLogoWaterMark(e, "logo")}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {logo.enable ?
                    <>
                        <div className="fileUpload"
                            style={logo.src ? { color: '#fff' } : {}}
                        >
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                            {name.logoName.length > 20 ? `${name.logoName.slice(0, 20)}...` : name.logoName}
                            <input
                                type="file"
                                className='cursor-pointer'
                                onChange={(e) => onLoadFile(e.target.files[0], "logo")}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loadLogo ?
                                                <i className="fa fa-spinner fa-spin loader-center" />
                                                : <img
                                                    src={logo.src ?
                                                        logo.src : sample}
                                                    alt=""
                                                    className="uploadImg"
                                                />
                                        }
                                    </li>
                                </ul>
                            </div>
                            {/* <div className="itemDisp-right">

                                <select
                                    onChange={(e) => logoWaterPos(e.target.value, 'logo')}
                                    className='cursor-pointer'>
                                    <option value={'top-left'}>Top Left</option>
                                    <option value={'top-center'}>Top Center</option>
                                    <option value={'top-right'}>Top Right</option>
                                    <option value={'bottom-left'}>Bottom Left</option>
                                    <option value={'bottom-center'}>Bottom Center</option>
                                    <option value={'bottom-right'}>Bottom Right</option>
                                </select>
                            </div> */}
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default LogoComp