import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import modalCloseIcon from "../../../../../images/modal-close.png";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import YourAudio from "./YourAudio";
import { useDispatch } from "react-redux";
import { onGetLibraryAudio, onGetUserUploadData } from "../../../../../Actions/EditorAction";
import { onChangeBgAudio } from "../../../../../Actions/CampaignAction";
import BgAudioLibrary from "./BgAudioLibrary";

const BackgroundAudioModal = ({ show, handleClose, campaign }) => {
    const dispatch = useDispatch();
    const [libraryAudio, setLibraryAudio] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);
    const [userAudio, setUserAudio] = useState([]);
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState({
        audioLibrary: false,
        userAudio: false
    });
    const [selectedMedia, setSelectedMedia] = useState(false)

    const handleChangeBgAudio = (data) => {
        setSelectedMedia(data)
    }

    const fetchLibraryAudio = (pageNo = 1) => {
        setLoader({ ...loader, audioLibrary: true })
        let data = {
            page_number: pageNo
        }
        dispatch(onGetLibraryAudio(data, libraryAudio, setLibraryAudio, loader, setLoader, setPageCount, setError, pageNo))
    }

    const fetchUserAudio = () => {
        let data = {
            type: "music"
        }
        dispatch(onGetUserUploadData(data, setUserAudio, loader, setLoader))
    }


    const handleSave = () => {
        dispatch(onChangeBgAudio(selectedMedia))
        handleClose()
    }

    useEffect(() => {
        fetchLibraryAudio()
        fetchUserAudio()
    }, [])



    return (
        <Modal className="VideoModal BackgroundAudioModal" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose">
                    <img alt="" src={modalCloseIcon} />
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="b" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Audio Library</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Your Audio</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <BgAudioLibrary
                                currentUrl={currentUrl}
                                setCurrentUrl={setCurrentUrl}
                                libraryAudio={libraryAudio}
                                pageCount={pageCount}
                                loader={loader}
                                error={error}
                                campaign={campaign}
                                fetchLibraryAudio={fetchLibraryAudio}
                                handleChangeBgAudio={handleChangeBgAudio}
                            />

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <YourAudio
                                currentUrl={currentUrl}
                                setCurrentUrl={setCurrentUrl}
                                loader={loader}
                                setLoader={setLoader}
                                userAudio={userAudio}
                                campaign={campaign}
                                fetchUserAudio={fetchUserAudio}
                                handleChangeBgAudio={handleChangeBgAudio}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <div className="btnTab text-end">
                    <button
                        className="create-modal-btn"
                        onClick={handleSave}
                    >Use</button>
                    <button className="create-modal-btn ml-2"
                        onClick={handleClose}
                    >Cancel</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BackgroundAudioModal
