import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../images/modal-close.png';

const ResolutionModal = (props) => {
    const [state, setState] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        props.setResolution(state)
        props.setShow(false)

    }
    return (
        <Modal className="VideoModal small reel_merge_model Syvid_modal" show={props.show} centered>
            <Modal.Body>
                <div onClick={() => props.setShow(false)} className="vidClose"><img src={modalCloseIcon} alt="" /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <div className="col-12 ">
                            <div className="text-center">
                                <h3>Select Resolution</h3>
                            </div>
                            <hr style={{ opacity: 1 }} />
                            <div className="set_resolution text-center mt-3" >
                                <div className='d-flex justify-content-center' >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="flexCheckDefault"
                                            name='resolution'
                                            onChange={() => setState("16:9")}
                                        />
                                        <span style={{ fontSize: "20px" }} for="flexCheckDefault">
                                            Horizontal 16:9 (1920X1080)
                                        </span>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="flexCheckChecked"
                                            name='resolution'
                                            onChange={() => setState("9:16")}
                                        />
                                        <span style={{ fontSize: "20px" }} for="flexCheckChecked">
                                            Vertical 9:16 (1080X1920)
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="set_btn_wrapper text-center">
                        <button type="submit" className="demoLink">Submit</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ResolutionModal