import React, { useEffect, useState } from 'react'
import { FaDownload, FaEdit, FaGlobe, FaTrash } from 'react-icons/fa'
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { onCheckStatus, onCheckStatus1, onDeleteCampaign } from '../../Actions/CampaignAction';
import SweetAlertTwo from '../SweetAlertTwo';
import defaultImage from '../../images/fav-new.png'
import PublishModal from './PublishModal';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useSelector } from 'react-redux';

const VideoCampRow = ({ curElem, fetchData }) => {
    let rendCheck = false
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [status, setStatus] = useState(-1)
    const [videoStatus, setVideoStatus] = useState(-1)
    const [show, setShow] = useState(false)

    const [info, setInfo] = useState({
        enable: false,
        id: -1
    })

    const [memberShip, setMemberShip] = useState([])

    const [checkData, setCheckData] = useState({
        renderProgress: curElem.renderProgress,
        renderStatus: "",
        videoUrl: "",
        errMessage: "",
        failedButton: "",
    })

    const handleCancel = () => {
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }

    const handleDelete = () => {
        setInfo({
            ...info,
            enable: true
        })
    }

    const handleOCP = (id) => {
        window.open(`https://backend.adsreel.io/ocp/${id}`, '_blank');
    }

    const handlePerform = (Swal) => {
        let data = {
            cId: curElem.id
        }
        dispatch(onDeleteCampaign(data, fetchData, Swal))
        setInfo({
            ...info,
            enable: false,
            id: -1
        })
    }


    const handleDownload = (url) => {
        let a = document.createElement('a')
        a.href = url
        a.download = "download.mp4"
        a.target = "_blank"
        a.click()
    }

    const checkStatus = (id) => {
        let data = {
            cId: id
        }
        dispatch(onCheckStatus(data, setVideoStatus, checkData, setCheckData))

    }

    const checkStatus1 = () => {
        let data = {
            cId: curElem.id,
        }
        dispatch(onCheckStatus1(data, setStatus, checkData, rendCheck, setCheckData, clearInterval, fetchData))

    }
    useEffect(() => {
        if (+videoStatus === 0 || +videoStatus === 6 || +videoStatus === 5) {
            setStatus(videoStatus)
            rendCheck = setInterval(() => {
                checkStatus1()
            }, 5000)
        } else {
            setStatus(videoStatus)
        }
        return () => {
            clearInterval(rendCheck)
        }
    }, [videoStatus])

    useEffect(() => {
        if (curElem) {
            
            setCheckData({
                ...checkData,
                failedButton: ""
            })
            setStatus(-1)
            setVideoStatus(-1)
            checkStatus(curElem.id)
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>

            <tr className="cursor-pointer">
                <td>
                    <span className="proSpace">
                        <img src={curElem.logo === "" ? defaultImage : curElem.logo} alt="" />
                    </span>
                </td>
                <td>{curElem.name}</td>
                <td className="text-capitalize">{curElem.type}<div></div></td>



                <td className='text-center' >
                    {videoStatus !== -1 ?
                        <>
                            <span
                                className="stat text-left"
                                style={{
                                    background:
                                        +status === 0 ? "#e0712c" :
                                            +status === 1 ? "rgb(22, 126, 215)" :
                                                +status === 2 ? "#05772b" :
                                                    +status === 3 ? "#e92437" :
                                                        +status === 5 ? "#5d00ff" :
                                                            +status === 6 ? "#a4a427" :
                                                                "3424",
                                    display: "inline-flex", width: "auto", alignItems: "center"
                                }}
                                data-tooltip-id={`my-tooltip${curElem.id}`}
                            >
                                {
                                    +status === 0 ?
                                        <>
                                            <div className="typing me-1" >
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            Generating Video
                                        </>
                                        :
                                        +status === 1
                                            ? "Awaiting Customization" :
                                            +status === 2
                                                ? "Render Successful" :
                                                +status === 3
                                                    ? <>
                                                        <Tooltip
                                                            id={`my-tooltip${curElem.id}`}
                                                            style={{ backgroundColor: "#424b6c", color: "#fff" }}
                                                            place="top"
                                                            content={checkData.errMessage}
                                                        />
                                                        {checkData.failedButton ? checkData.failedButton : "Render Failed"}
                                                    </>
                                                    :
                                                    + status === 5 ?
                                                        <>
                                                            <div className="typing me-1" >
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </div>
                                                            Downloading Resources
                                                        </>
                                                        : +status === 6
                                                            ?
                                                            <>
                                                                <div className="typing me-1" >
                                                                    <span></span>
                                                                    <span></span>
                                                                    <span></span>
                                                                </div>
                                                                Rendering {checkData.renderProgress}%
                                                            </>
                                                            : ""
                                }
                            </span>
                        </> : ""}
                </td>
                <td>{curElem.created}</td>
                <td>
                    {videoStatus !== -1 ?
                        <>
                            {
                                +auth.user.is_client_account === 1 ? "" :
                                    +status !== 0 && (checkData.failedButton === "Render Failed" || checkData.failedButton === "" || checkData.failedButton ==="Render completed") ?
                                        <Link className="roundBox" title='Edit' to={`/editor?cId=${curElem.id}`}  ><FaEdit /></Link>
                                        : ""
                            }
                            {
                                (+status === 2 || +status === 3) && (checkData.videoUrl && checkData.videoUrl !== "") ?
                                    <>
                                        <span className="roundBox" title='Download' onClick={() => handleDownload(checkData.videoUrl)} ><FaDownload /></span>
                                        {+auth.user.is_client_account === 1 ? "" :
                                            <span className="roundBox" title='Publish' onClick={() => setShow(true)}><FaGlobe /></span>}
                                        {memberShip.includes("enterprise") || memberShip.includes("bundle") ?
                                            <span className="roundBox" title='OCP' onClick={() => handleOCP(curElem.id)} >
                                                <i className="fa fa-desktop" aria-hidden="true"></i>
                                            </span> : ""}

                                    </>
                                    : ""
                            }
                            {+auth.user.is_client_account === 1 ? "" :
                                <span className="roundBox" title='Delete' onClick={handleDelete} ><FaTrash /></span>
                            }
                        </>
                        : ""}

                </td>




                <SweetAlertTwo
                    show={info.enable}
                    title="Are You Sure?"
                    text={`Do you want to delete this video!`}
                    icon="warning"
                    showCancelButton={true}
                    confirmButtonColor='#b32f3d'
                    cancelButtonColor='#2a2c3a'
                    confirmButtonText='Yes, Delete!'
                    confirmTitle='Deleting...'
                    confirmText="This won't be revert!"
                    confirmIcon='success'
                    handlePerform={handlePerform}
                    onCancel={handleCancel}
                />
            </tr >
            <PublishModal
                curElem={curElem}
                show={show}
                setShow={setShow}
                videoUrl={checkData.videoUrl}
            />
        </>
    )
}

export default VideoCampRow
