import React, { useState } from 'react'
import { onDownloadYoutubeVideo } from '../../Actions/TrendAction'
import { useDispatch } from 'react-redux'

const YoutubeCard = ({ curElem }) => {

    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)

    const downloadYoutube = () => {
        setLoader(true)
        let data = {
            id: curElem.id
        }
        dispatch(onDownloadYoutubeVideo(data, setLoader))
    }
    return (

        <tr>
            <td style={{ width: "180px" }}>
                {

                    <div>
                        <div className='ytImg'><img
                            style={{ width: "100%" }}
                            src={curElem.thumbnail}
                            className="img-fluid" /></div>

                        <ul className="downLink m-0 d-flex justify-content-between">
                            <li>
                                <a
                                    target="_blank"
                                    href={`https://www.youtube.com/watch?v=${curElem.id}`}
                                >
                                    <span
                                        style={{ fontSize: "10px" }}><i
                                            className="fas fa-play-circle"></i></span>Watch
                                </a>
                            </li>
                            <li>
                                <a style={{ cursor: "pointer" }} onClick={downloadYoutube}>
                                    <span>{loader ? <>Downloading <i className="fa fa-spinner fa-spin mx-1" /></> : <><i className="fas fa-download" />Download</>}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                }
            </td>
            <td style={{ width: "50%", paddingLeft: '15px' }}>
                <h6 className="m-0">{curElem.title}</h6>
                <p><span
                    className="d-block"><a
                        target="_blank" href={`https://www.youtube.com/channel/${curElem.channel_id}`}>{curElem.channel_title}</a>- 2 months ago</span></p>

                <ul className="d-flex flex-wrap list-inline">
                    {
                        curElem.tags !== null ?
                            curElem.tags.length > 0 ?
                                curElem.tags.slice(0, 10).map((item, index) => {
                                    return (
                                        <li key={index}
                                            style={{
                                                backgroundColor: "#fff",
                                                margin: "3px",
                                                padding: "3px",
                                                color: "#000",
                                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
                                                fontSize: "10px",
                                                borderRadius: "5px"
                                            }}>
                                            <span
                                                style={{
                                                    fontSize: "10px",
                                                    marginRight: "3px"
                                                }}><i
                                                    className="fas fa-tag"></i></span>
                                            {item}
                                        </li>
                                    )
                                })
                                : ''
                            : ''
                    }
                </ul>
            </td>
            <td style={{ fontWeight: "400" }} >{curElem.stats.views}</td>
            <td style={{ fontWeight: "400" }} >{curElem.stats.like}</td>
            <td style={{ fontWeight: "400" }} >{curElem.stats.dislike}</td>
            <td style={{ fontWeight: "400" }} >{curElem.stats.comments}</td>
        </tr>
    )
}

export default YoutubeCard
