import React, { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import HoverVideoPlayer from "react-hover-video-player";
import Cropper from "react-easy-crop";
import closeModalx from "../../../../images/modal-close.png"
import { addMediaLayer, onLoadMedia, onVideoCrop, updateBackground, updateMediaLayer } from "../../../../Actions/CampaignAction";



const BgVideoMedia = ({ selectedSlideIndex, selectedLayerIndex, selectedSlide, type, selectedLayer }) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputVideo, setOutputVideo] = useState(false);
    const [outputThumb, setOutputThumb] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'video',
        page: 1
    });

    const [videoData, setVideoData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });
        setCheckImage(false)
    }

    const searchVideo = (page = 1) => {
        setLoader(true);
        let data = {
            keyword: search.keyword,
            type: search.search_type,
            page_number: page
        }
        dispatch(onLoadMedia(data, videoData, setVideoData, search, setSearch, page, setError, setLoader))
    }

    const handleVideoClick = () => {
        setError(false);
        setVideoData([]);
        searchVideo();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setVideoData([]);
        searchVideo();
    }


    const selectVideo = async (video, index) => {
        let data = {
            url: video.url,
            thumbnail: video.thumbnail
        }
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video" || type === "gif") {
                    dispatch(updateMediaLayer(data, selectedSlideIndex, selectedLayerIndex, "video"))
                }
                else {
                    dispatch(addMediaLayer(data, selectedSlideIndex, "video", false))
                }
                setCheckImage(index);

            } else {
                dispatch(addMediaLayer(data, selectedSlideIndex, "video", false))
                setCheckImage(index);

            }
        } else {
            return new Promise((resolve, reject) => {
                let au = document.createElement('video');
                au.src = video.url
                au.addEventListener('loadedmetadata', function () {
                    setCheckImage(index);
                    video.bgDuration = Math.round(au.duration)
                    dispatch(updateBackground(video, selectedSlideIndex, "video"))
                    resolve(au.duration)
                }, false);
            })
        }

    }

    const selectCropImage = (video) => {
        let jsonData = {
            url: video,
            thumbnail: outputThumb,
        }
        setCropModel(false);
        setOutputVideo(false);
        if (type === "media") {
            if (selectedLayerIndex !== -1) {
                const type = selectedSlide.layers[selectedLayerIndex].type
                if (type === "image" || type === "video") {
                    dispatch(updateMediaLayer(jsonData, selectedSlideIndex, selectedLayerIndex, "video"))
                }
                else {
                    dispatch(addMediaLayer(jsonData, selectedSlideIndex, "video", false))
                }
            } else {
                dispatch(addMediaLayer(jsonData, selectedSlideIndex, "video", false))
            }
        } else {
            return new Promise((resolve, reject) => {
                let au = document.createElement('video');
                au.src = jsonData.url
                au.addEventListener('loadedmetadata', function () {
                    jsonData.bgDuration = Math.round(au.duration)
                    dispatch(updateBackground(jsonData, selectedSlideIndex, "video"))
                    resolve(au.duration)
                }, false);
            })
        }

    }

    const selectCrop = (e, url) => {
        e.stopPropagation()
        setCropModel(true);
        setCurrentImage(url);
        setOutputVideo(false)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)

    }, [])

    const cropVideoFile = () => {
        setCropLoader(true);
        const formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', currentImage);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        dispatch(onVideoCrop(formData, setOutputVideo, setOutputThumb, setCropLoader))

    }


    return (
        <>
            <div className="tab-outer" >
                <form className="tabSearch" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="tabSearch mt-3">
                        <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => onInputChange(e)}
                        />
                        <span
                            className="inp-icon"
                            onClick={() => handleVideoClick()}
                        ><i className="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </form>
                <div className="audio-library mt-3" id={`scrollableVideo_${type}`} style={{ maxHeight: "655px" }}>
                    <InfiniteScroll
                        dataLength={videoData.length} //This is important field to render the next data
                        next={() => searchVideo(search.page + 1)}
                        hasMore={true}
                        scrollableTarget={`scrollableVideo_${type}`}
                    >
                        <ul>
                            {videoData.length > 0 ?
                                videoData.map((curElem, index) => {
                                    return (
                                        <>
                                            {curElem ?
                                                <li className='col-md-6 cursor-pointer p-1' key={index} onClick={() => selectVideo(curElem, index)}>
                                                    <div className="audio-library-single">

                                                        <HoverVideoPlayer
                                                            className="hover-video-player"
                                                            style={{ height: '100%', overflow: 'hidden' }}
                                                            videoSrc={curElem.url}
                                                            pausedOverlay={
                                                                <img src={curElem.thumbnail} alt={`${search.keyword + ' ' + index}`} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                            }
                                                            loadingOverlay={
                                                                <div><i className="fa fa-spinner fa-spin hover-loader-center" style={{ color: "#e0712c" }} /></div>
                                                            }
                                                        />
                                                        {checkImage === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                            : ''
                                                        }
                                                        <div className="crop-icon" onClick={(e) => selectCrop(e, curElem.url)}>
                                                            <i className="fa fa-crop"></i>
                                                        </div>
                                                    </div>
                                                </li> : ''
                                            }
                                        </>

                                    )
                                }) : ''}
                            {
                                loader ?
                                    <div className="col-md-12">
                                        <h4 className='text-center'>
                                            <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c" }} />
                                        </h4>
                                    </div>
                                    : ''
                            }
                            {
                                error && videoData.length === 0 ?
                                    <div className="col-md-12">
                                        <h6 className='text-center'>{error}</h6>
                                    </div>
                                    : ''
                            }
                        </ul>
                    </InfiniteScroll>
                </div>

                <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{ height: "auto" }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content crop-video">
                            <div className="modal-header video-crop-header">
                                <h5 className="modal-title text-center" style={{ marginTop: '12px' }}>Crop</h5>
                                <button onClick={(e) => setCropModel(false)} type="button" className="close img-close" data-dismiss="modal"
                                    aria-label="Close">
                                    {/* <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span> */}
                                    <img src={closeModalx} alt="" style={{ marginTop: '12px' }} />
                                </button>
                            </div>
                            <div className="modal-body" style={{ position: "relative", height: "54vh" }}>

                                <div className="tab-content text-white" id="pills-tabContent">
                                    <div className="tab-pane editor-panel fade show active" id="pills-crop"
                                        role="tabpanel" aria-labelledby="pills-story-tab">
                                        <Cropper
                                            video={currentImage}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={16 / 9}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                        />
                                        {
                                            croppedAreaPixels === null ?
                                                <div className="crop-icon-center" style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                }}>
                                                    <i className="fa fa-spinner fa-spin mr-2" style={{ color: "#CE88FF", fontSize: '25px', }} />
                                                </div> : ''
                                        }
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-trim"
                                        role="tabpanel" aria-labelledby="pills-story-tab">
                                        <h4>Trim Video</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                                <button className="btn-custom mr-1" type="button" onClick={() => cropVideoFile()}>
                                    {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                    {cropLoader ? 'Cropping' : 'Crop'}
                                </button>
                                <button
                                    className={`${outputVideo === false ? 'btn-custom-disable text-secondary' : 'btn-custom'}`}
                                    type="button"
                                    disabled={outputVideo === false ? true : false}
                                    onClick={() => selectCropImage(outputVideo)} >Use</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default BgVideoMedia;
