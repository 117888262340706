import React from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import { BsSearch } from 'react-icons/bs'
import { useState } from "react";
import { useEffect } from "react";
import { onGetAllCampaign, onFetchMergeCampaigns } from "../../Actions/CampaignAction";
import { useDispatch } from "react-redux";

import VideoCampRow from "./VideoCampRow";
import Pagination from "../User/Pagination";
import MergeCard from "../ReelMerge/MergeCard";

const Projects = () => {

    const dispatch = useDispatch()
    const [campaignsData, setCampaignsData] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [mergeData, setMergeData] = useState([])
    const [mergeVideo, setMergeVideo] = useState([])
    const [campLoader, setCampLoader] = useState(false)
    const [mergeLoader, setMergeLoader] = useState(false)
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });
    const [mergePagination, setMergePagination] = useState({
        currentPage: 1,
        totalItemonPage: 10
    });

    const [filterObj, setFilterObj] = useState({
        key: "",
        searchKey: "",
    })
    const [projectType, setProjectType] = useState("project")

    const indexofLastTodo = pagination.currentPage * pagination.totalItemonPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemonPage;
    const mergeIndexofLastTodo = mergePagination.currentPage * mergePagination.totalItemonPage;
    const mergeIndexofFirstTodo = mergeIndexofLastTodo - mergePagination.totalItemonPage;

    const currentTodo =
        (filterObj.searchKey === "" && filterObj.key !== "") || (filterObj.searchKey !== "" && filterObj.key === "") || (filterObj.searchKey !== "" && filterObj.key !== "")
            ? campaignsData
            : campaignsData.slice(indexofFirstTodo, indexofLastTodo);

    const currentMergeTodo =
        filterObj.searchKey !== "" ? mergeData : mergeData.slice(mergeIndexofFirstTodo, mergeIndexofLastTodo);

    const fetchCampaigns = () => {
        setCampLoader(true)
        dispatch(onGetAllCampaign(setCampaigns, setCampLoader))
    }

    const fetchMergeCampaign = () => {
        setMergeLoader(true)
        dispatch(onFetchMergeCampaigns(setMergeVideo, setMergeLoader))
    }

    const onChangeProjectType = (e) => {
        setProjectType(e.target.value)
        // if (e.target.value === "merge") {
        //     fetchMergeCampaign()
        // }
    }

    useEffect(() => {
        if (projectType === "project") {
            if (filterObj.searchKey === "" && filterObj.key === "") {
                setCampaignsData(campaigns)
            }
            else {
                let dataArr = campaigns.filter((item) => {
                    if (item.type === filterObj.key) {
                        return item
                    }
                    else if (filterObj.key === "") {
                        return item
                    }
                }).filter((item1) => {
                    return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
                })
                setPagination({
                    ...pagination,
                    currentPage: 1,
                    totalItemonPage: 10
                })
                setCampaignsData(dataArr)
            }
        }

    }, [filterObj, campaigns, projectType])

    useEffect(() => {
        if (projectType === "merge") {

            // merge
            if (filterObj.searchKey === "") {
                setMergeData(mergeVideo)
            }
            else {
                let mergeArr = mergeVideo.filter((item) => {
                    return item.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
                })
                setMergePagination({
                    ...mergePagination,
                    currentPage: 1,
                    totalItemonPage: 10
                })
                setMergeData(mergeArr)
            }
        }

    }, [filterObj, mergeVideo, projectType])

    useEffect(() => {
        setPagination({
            ...pagination,
            currentPage: 1,
            totalItemonPage: 10
        })
        setMergePagination({
            ...mergePagination,
            currentPage: 1,
            totalItemonPage: 10
        })
        setCampaigns([])
        setMergeVideo([])
        fetchCampaigns()
        fetchMergeCampaign()
    }, [])

    return (
        <>
            <TitleBar title="Project" />
            <Navbar />
            <section className="siteWrap">
                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>Projects</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="siteWrap-in siteWrapN pt-3 pb-3">

                    <div className="container">
                        <div className="business-list">
                            <div className="business-search">
                                <div className="business-search-inp">
                                    <span className="searchIcon">
                                        <BsSearch />
                                    </span>
                                    <input
                                        className="inpSearch withIcon"
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => setFilterObj({
                                            ...filterObj,
                                            searchKey: e.target.value
                                        })}
                                    />
                                </div>
                                {projectType === 'project' ?
                                    <div className="business-search-inp ml-2">
                                        <select className="inpSearch"
                                            onChange={(e) => setFilterObj({
                                                ...filterObj,
                                                key: e.target.value
                                            })}
                                            value={filterObj.key}
                                        >
                                            <option value="">Select Mode</option>
                                            <option value="places">Places</option>
                                            <option value="search">Search</option>
                                            <option value="domain">Domain</option>
                                            <option value="custom">Custom</option>
                                        </select>
                                    </div>
                                    : ""
                                }
                                <div className="business-search-inp ml-2">
                                    <select className="inpSearch"
                                        onChange={(e) => onChangeProjectType(e)}
                                        value={projectType}
                                    >
                                        <option value="project">Project</option>
                                        <option value="merge">Merge</option>
                                    </select>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table businessTable">
                                    <thead>
                                        <tr>
                                            <th width="150"></th>
                                            <th>Name</th>
                                            <th >Mode</th>
                                            <th className="text-center">Status</th>
                                            <th>Created</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {projectType === 'project' ?
                                            currentTodo.length > 0 ?
                                                currentTodo.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <VideoCampRow
                                                                curElem={curElem}
                                                                fetchData={fetchCampaigns}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : filterObj.key === "" && filterObj.searchKey === "" ?
                                                    <tr className="text-center table-border-remove">
                                                        <td colSpan={6}>
                                                            {campLoader ?
                                                                <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: 35 }} />
                                                                : "No Campaign generated yet!"}
                                                        </td>
                                                    </tr> : ""
                                            :

                                            currentMergeTodo.length > 0 ?
                                                currentMergeTodo.map((curElem, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <MergeCard
                                                                curElem={curElem}
                                                                fetchData={fetchMergeCampaign}
                                                            />
                                                        </React.Fragment>
                                                    )
                                                })
                                                : filterObj.searchKey === "" ?
                                                    <tr className="text-center table-border-remove">
                                                        <td colSpan={6}>
                                                            {mergeLoader ?
                                                                <i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: 35 }} />
                                                                : "No Campaign generated yet!"}
                                                        </td>
                                                    </tr> : ""
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="tablepage">
                                <div className="tablepage">
                                    {projectType === 'project' ?
                                        <Pagination
                                            listData={campaignsData}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            currentTodo={currentTodo}
                                            listArr={campaigns}
                                            loader={campLoader}
                                        />

                                        :

                                        // merge pagination
                                        < Pagination
                                            listData={mergeData}
                                            pagination={mergePagination}
                                            setPagination={setMergePagination}
                                            currentTodo={currentMergeTodo}
                                            listArr={mergeVideo}
                                            loader={mergeLoader}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Projects;