import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import BgImageMedia from '../Background/BgImageMedia';
import BgVideoMedia from '../Background/BgVideoMedia';
import UploadMedia from '../Background/UploadMedia';
import BusinessGraphic from '../Background/BusinessGraphic';

const Media = ({ slideData }) => {

    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
    const selectedLayer = slideData[selectedSlideIndex].layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = slideData[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)

    return (
        <div className="innertab-scroll" style={{ padding: "0 50px" }}>
            <div className="mediaList">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="b" className="inTabNav">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Image</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Library</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fourth">Business Graphic</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content >
                        <Tab.Pane eventKey="first">
                            <BgImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <BgVideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <UploadMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                selectedLayer={selectedLayer}
                                type="media"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <BusinessGraphic
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                selectedSlide={selectedSlide}
                                type="media"
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    )
}

export default Media
