import React from 'react'
import sample from "../../../../images/sample.png";

const WaterComp = ({ waterMark, name, handleLogoWaterMark, logoWaterPos, onLoadFile, loadWatermark }) => {
    return (
        <div className="add-block-single mt-0">
            <div className="add-block-main">
                <div className="add-block-title  mb-4">
                    <h2>Add Watermark</h2>
                    <div className="switch-single">
                        <label className="switch" htmlFor="watermark">
                            <input
                                type="checkbox"
                                id="watermark"
                                checked={waterMark?.enable}
                                onChange={(e) => handleLogoWaterMark(e, "watermark")}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                {waterMark?.enable ?
                    <>
                        <div className="fileUpload"
                            style={waterMark?.src !== "" ? { color: '#fff' } : {}}
                        >
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                            {name.waterMarkName.length > 23 ? `${name.waterMarkName.slice(0, 23)}...` : name.waterMarkName}
                            <input
                                type="file"
                                id="inputGroupFile01"
                                className='cursor-pointer'
                                onChange={(e) => onLoadFile(e.target.files[0], 'watermark')}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loadWatermark ?
                                                <i className="fa fa-spinner fa-spin loader-center" />
                                                : <img
                                                    src={waterMark?.src ? waterMark?.src : sample}
                                                    alt=""
                                                    className="uploadImg"
                                                />
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className="itemDisp-right">
                                <select
                                    onChange={(e) => logoWaterPos(e.target.value, 'watermark')}
                                    className='cursor-pointer'>
                                    <option value={'top-left'}>Top Left</option>
                                    <option value={'top-center'}>Top Center</option>
                                    <option value={'top-right'}>Top Right</option>
                                    <option value={'bottom-left'}>Bottom Left</option>
                                    <option value={'bottom-center'}>Bottom Center</option>
                                    <option value={'bottom-right'}>Bottom Right</option>
                                </select>
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default WaterComp