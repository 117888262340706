import React, { useState } from "react";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { onFetchCountry, onFetchState, onFetchCity, onCreateCampaign, onFetchBusinessDetails, onFetchFontEditor } from '../../Actions/CampaignAction'
import { useEffect } from "react";
import PlacesCard from "./Scrape/PlacesCard";
import UpdatedLinksCard from "./Scrape/UpdatedLinksCard";
import CustomForm from "./CustomForm";
import KnowledgeGraphCard from "./Scrape/KnowledgeGraphCard";
import { fontNotSet } from "../../Global/FontFamilyData";
const CreateAddVideo = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [fonts, setFonts] = useState([])
    const [state, setState] = useState({
        cId: "",
        campaignType: "campaignInsert",
        campaignName: "",
        type: "places",
        keyword: "",
        domain: "",
        country: "",
        state: "",
        city: "",
        businessName: "",
        businessAddress: "",
        businessPhone: "",
        businessFont: "",
        aboutBusiness: "",
        images: "",
        videos: "",
        logo: ""
    });

    const [scrapeData, setScrapeData] = useState({
        places: [],
        updateLinks: [],
        knowledgeGraph: []
    });
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [loader, setLoader] = useState({
        submitLoader: false,
        detailsLoader: false
    });
    const [scrappingLoader, setScrappingLoader] = useState({
        enable: false,
        index: -1
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "type") {
            setState({
                cId: state.cId,
                campaignName: state.campaignName,
                type: value,
                campaignType: state.campaignType,
                keyword: "",
                domain: "",
                country: "",
                state: "",
                city: "",
                businessName: "",
                businessAddress: "",
                businessPhone: "",
                businessFont: "",
                aboutBusiness: "",
                images: [],
                videos: [],
                logo: ""
            })
            setCountryData([])
            setStateData([])
            setCityData([])
        } else {
            setState({ ...state, [name]: value })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, submitLoader: true });
        setScrapeData({
            places: [],
            updateLinks: [],
            knowledgeGraph: []
        })
        let data = { ...state }
        if (state.type === "search" || state.type === "places" || state.type === "domain") {
            data = {
                cId: state.cId,
                campaignName: state.campaignName,
                type: state.type,
                campaignType: state.campaignType,
                keyword: state.keyword,
                country: state.country.iso2 ? state.country.iso2 : '',
                state: state.state.iso2 ? state.state.iso2 : '',
                city: state.city,
                domain: state.domain
            }
        } else {
            data = {
                ...data,
                campaignType: "campaignInsert"
            }
            delete data.cId
            delete data.country
            delete data.city
            delete data.state
            delete data.domain
            delete data.keyword
        }
        dispatch(onCreateCampaign(data, state, setState, setScrapeData, navigate, loader, setLoader))
    }

    const handleScrapping = (url, mapLink, placeId, index, moreData) => {
        if (url !== "" && !scrappingLoader.enable) {
            setScrappingLoader({ ...scrappingLoader, enable: true, index: index })
            let data = {
                placeId: placeId ? placeId : "",
                url: url ? url : "",
                mapLink: mapLink ? mapLink : "",
                cId: state.cId,
                title: moreData.title,
                address: moreData.address,
                category: moreData.category
            }
            dispatch(onFetchBusinessDetails(data, state, navigate, setScrappingLoader))
        }
    }

    const openWebsite = (e, url) => {
        e.stopPropagation()
        window.open(url, '_blank');
    }

    const fetchCountry = () => {
        dispatch(onFetchCountry(setCountryData))
    }

    const onSelect = (e) => {
        const { name, value } = e.target;
        let data = JSON.parse(value)
        setState({ ...state, [name]: data })
        if (name === "country") {
            fetchState(data.id)
            setStateData([]);
            setCityData([]);
        } else {
            fetchCity(state.country.id, data.id)
            setCityData([]);
        }
    }
    const fetchState = (cid) => {
        let data = {
            "countryId": cid
        }
        dispatch(onFetchState(data, setStateData))
    }

    const fetchCity = (cid, sid) => {
        let data = {
            "countryId": cid,
            "stateId": sid
        }
        dispatch(onFetchCity(data, setCityData))
    }

    useEffect(() => {
        if (state.type === "places" || state.type === "search" || state.type === "domain") {
            fetchCountry();
        } else {
            dispatch(onFetchFontEditor(setFonts, fontNotSet))
        }
    }, [state.type])

    return (
        <>
            <TitleBar title="Create Add Video" />
            <Navbar />

            <div className="siteWrap">
                <div className="projectTitle-wrap">
                    <div className="container">
                        <div className="projectTitle">
                            <div className="projectTitle-left">
                                <h2>Create Ads Video</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="add-video-wrap">
                    <div className="container">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <div className="form-group form-group-alt">
                                        <label htmlFor="form-text text-muted">Campaign Name</label>
                                        <input
                                            type="text"
                                            name="campaignName"
                                            className="form-control"
                                            placeholder="Enter Campaign Name"
                                            value={state.campaignName}
                                            onChange={handleChange}
                                            required
                                        >
                                        </input>
                                    </div>
                                </div>
                                < div className="col-lg-6">
                                    <div className="form-group form-group-alt">
                                        <label htmlFor="form-text text-muted">Search Type</label>
                                        <select
                                            class="form-select form-control"
                                            aria-label="Default select example"
                                            name="type"
                                            onChange={handleChange}
                                            value={state.type}
                                            required
                                        >
                                            <option value="search">Search</option>
                                            <option value="places">Places</option>
                                            <option value="domain">Domain</option>
                                            <option value="custom">Custom</option>
                                        </select>


                                    </div>
                                </div>
                                {
                                    state.type === "custom" ?
                                        <CustomForm state={state} setState={setState} handleChange={handleChange} fonts={fonts} />
                                        :
                                        <>

                                            {state.type === "domain" ?
                                                < div className="col-lg-12">
                                                    <div className="form-group form-group-alt">
                                                        <label htmlFor="form-text text-muted">Domain</label>
                                                        <input
                                                            type="text"
                                                            name="domain"
                                                            className="form-control"
                                                            placeholder="Enter Domain"
                                                            value={state.domain}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                                :

                                                < div className="col-lg-12">
                                                    <div className="form-group form-group-alt">
                                                        <label htmlFor="form-text text-muted">Keyword</label>
                                                        <input
                                                            type="text"
                                                            name="keyword"
                                                            className="form-control"
                                                            placeholder="Enter Keyword"
                                                            value={state.keyword}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                            }


                                            <div className="col-lg-4">
                                                <div className="form-group form-group-alt">
                                                    <label htmlFor="form-text text-muted">Country</label>
                                                    <select
                                                        class="form-select form-control"
                                                        aria-label="Default select example"
                                                        name="country"
                                                        onChange={(e) => onSelect(e)}
                                                        required
                                                    >
                                                        <option selected value="">Select Country</option>
                                                        {countryData.length > 0 ?
                                                            countryData.map((curElem, index) => {
                                                                return (
                                                                    <option key={index} value={JSON.stringify(curElem)}>{curElem.name}</option>
                                                                )
                                                            })
                                                            : ""
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="form-group form-group-alt">
                                                    <label htmlFor="form-text text-muted">State</label>
                                                    <select
                                                        class="form-select form-control"
                                                        aria-label="Default select example"
                                                        name="state"
                                                        onChange={onSelect}
                                                    >
                                                        <option selected value="">Select State</option>
                                                        {stateData.length > 0 ?
                                                            stateData.map((curElem) => {
                                                                return (
                                                                    <option value={JSON.stringify(curElem)}>{curElem.name}</option>
                                                                )
                                                            })
                                                            : ""
                                                        }
                                                    </select>


                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group form-group-alt">
                                                    <label htmlFor="form-text text-muted">City</label>
                                                    <select
                                                        class="form-select form-control"
                                                        aria-label="Default select example"
                                                        name="city"
                                                        value={state.city}
                                                        onChange={handleChange}
                                                    >
                                                        <option selected value="">Select City</option>
                                                        {cityData.length > 0 ?
                                                            cityData.map((curElem, index) => {
                                                                return (
                                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                                )
                                                            })
                                                            : ""
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                }
                            </div>
                            <button type="submit" className="fbtn btn-block demoLink">{loader.submitLoader ? <>Submitting<i className="fa fa-spinner fa-spin mx-1" /> </> : `Submit`}</button>
                        </form >
                    </div>
                </div >

                < div className="add-video-wrap-data" >
                    <div className="container">
                        <div className="row">
                            {loader.detailsLoader ?
                                <div className="col-md-12">
                                    <h4 className="text-center load-icon-help mt-3"><i className="fa fa-spinner fa-spin" style={{ color: "#e0712c", fontSize: "34px" }} /></h4>
                                </div> : ''
                            }
                            {
                                scrapeData.places.length > 0 ?
                                    scrapeData.places.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <PlacesCard
                                                    curElem={curElem}
                                                    index={index}
                                                    handleScrapping={handleScrapping}
                                                    openWebsite={openWebsite}
                                                    scrappingLoader={scrappingLoader}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    : ""
                            }
                            {
                                scrapeData.updateLinks.length > 0 ?
                                    scrapeData.updateLinks.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <UpdatedLinksCard
                                                    curElem={curElem}
                                                    index={index}
                                                    handleScrapping={handleScrapping}
                                                    openWebsite={openWebsite}
                                                    scrappingLoader={scrappingLoader}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    : ""
                            }
                            {
                                scrapeData.knowledgeGraph.length > 0 ?
                                    scrapeData.knowledgeGraph.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <KnowledgeGraphCard
                                                    curElem={curElem}
                                                    index={index}
                                                    handleScrapping={handleScrapping}
                                                    openWebsite={openWebsite}
                                                    scrappingLoader={scrappingLoader}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    : ""
                            }
                        </div>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    )
}

export default CreateAddVideo;