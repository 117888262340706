import React, { useEffect, useState } from 'react'
import { BsPauseFill, BsPlayFill, BsTrashFill } from 'react-icons/bs';
import SweetAlertTwo from '../../../../SweetAlertTwo';

let audio = false;
const AudioPlayer = ({ url, title, currentUrl, setCurrentUrl, handleChangeAudio, type, id, removeMedia }) => {
    const [audioDuration, setAudioDuration] = useState(0);
    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [info, setInfo] = useState({
        id: -1,
        enable: false
    })
    let clearPause;
    const playAudio = (e, url) => {
        e.stopPropagation()
        if (audioDuration > 0) {
            if (audio !== false) {
                audio.pause();
                setPlay(false);
            }
            setLoader(true);
            audio = new Audio(url);
            audio.play()
                .then(r => setLoader(false)).catch(err => console.log(err));
            setCurrentUrl(url);
            setPlay(true)
            clearPause = setTimeout(() => {
                pauseAudio(url)
            }, audioDuration * 1000)
        }
    }

    const pauseAudio = (e) => {
        e.stopPropagation()
        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const handleSelectAudio = () => {
        if (audioDuration) {
            let data = {
                url: url,
                title: title,
                duration: audioDuration
            }
            setCurrentUrl(url)
            handleChangeAudio(data)
        }
    }

    const deleteFile = (e) => {
        e.stopPropagation()
        setInfo({
            ...info,
            id: id,
            enable: true
        })
    }

    const handleCancel = () => {
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }
    const deleteAudio = (Swal) => {
        removeMedia(info.id, Swal)
        setInfo({
            ...info,
            id: -1,
            enable: false
        })
    }

    useEffect(() => {
        let au = document.createElement('audio');
        au.src = url;

        au.addEventListener('loadedmetadata', function () {
            if (au.duration !== Infinity && au.duration !== 0) {
                setAudioDuration(Number((au.duration).toFixed(1)));
            }
            else {
                var getDuration = function (url, next) {
                    var _player = new Audio(url);
                    _player.addEventListener("durationchange", function (e) {
                        if (this.duration != Infinity) {
                            var duration = this.duration
                            _player.remove();
                            next(duration);
                        };
                    }, false);
                    _player.load();
                    _player.currentTime = 24 * 60 * 60;
                    _player.volume = 0;
                    _player.play();
                };

                getDuration(au.src, function (duration) {
                    setAudioDuration(Number((duration).toFixed(1)));
                });
            }
        }, false);
    }, [])



    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])

    return (
        <li className="cursor-pointer mb-1" onClick={() => handleSelectAudio()}>
            <div className={`audio-library-single ${currentUrl === url ? 'active-audio' : ''}`}>
                {type === "upload" ? <div className="card-icon-delete" onClick={(e) => deleteFile(e)}>
                    <BsTrashFill color='#e0712c' />
                </div> : ""}
                <p className='text-right pr-2 text-light' style={{ fontSize: 12 }}>{audioDuration}s</p>
                <div className="audio-details"  >
                    {play ?
                        <div className="audio-play" onClick={(e) => pauseAudio(e)}>
                            {loader ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 24 }} /> : <BsPauseFill />}</div>
                        :
                        <div className="audio-play" onClick={(e) => playAudio(e, url)}> <BsPlayFill /></div>
                    }
                    <h6 className='mt-2'>{title}</h6>
                </div>
            </div >

            <SweetAlertTwo
                show={info.enable}
                title="Are You Sure?"
                text="You won't be able to revert this!"
                icon="warning"
                showCancelButton={true}
                confirmButtonColor='#b32f3d'
                cancelButtonColor='#2a2c3a'
                confirmButtonText='Yes, delete it!'
                confirmTitle='Deleting...'
                confirmText="This won't be revert!"
                confirmIcon='success'
                handlePerform={deleteAudio}
                onCancel={handleCancel}
            />
        </li >
    )
}

export default AudioPlayer
